<template>
  <div>
    <div class="single" v-for="item in memberList" :key="item.id">
      <img
        style="width: 30px;height: 30px;"
        v-if="item.group.avatar !== null"
        :src="item.group.avatar"
        alt=""
      />
      <img
        v-else
        width="30px"
        height="30px"
        style="width: 30px;height: 30px;"
        src="@/assets/imgs/mrtx.png"
        alt=""
      />
      <div
        class="group-name"
        :class="item.group.id == selectId ? 'select-id' : ''"
      >
        <div>{{ item.group.name }}</div>
        <div>{{ item.group.id }}</div>
      </div>
      <el-popover
        placement="right"
        width="120"
        trigger="click"
        popper-class="group-page-popover"
      >
        <div class="group-detail-popover-box">
          <div
            class="more-item"
            @click="$router.push(`/group/detail/${item.group.id}`)"
          >
            <span>群检索</span>
          </div>

          <div class="more-item" @click="selectFun(item.group.id)">
            <span>群发言</span>
          </div>
        </div>
        <img
          width="25px"
          height="25px"
          slot="reference"
          src="@/assets/imgs/more.png"
          alt=""
        />
      </el-popover>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserGroupCard',
  props: {
    memberList: Array
  },
  data() {
    return {
      selectId: ''
    }
  },
  methods: {
    selectFun(id) {
      this.selectId = id
      this.$emit('reciverUserGroup', id)
    }
  }
}
</script>

<style lang="scss" scoped>
.single {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
  border-bottom: 1px solid #f2f4f8;
  font-size: 12px;
  color: #121111;
  align-items: center;
  .select-id {
    color: rgb(62, 105, 245);
  }
  .group-name {
    max-width: 170px;
  }
}
</style>
