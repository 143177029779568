<template>
  <div class="app-container">
    <div class="left_box">
      <UserCard
        v-if="userInfo !== null"
        :groupInfo="userInfo"
        @labelFormUpdateFun="labelFormUpdateFun"
      />
      <div class="club_box">
        所在群(
        <span>{{ total }}</span>
        )
        <el-button
          class="btn"
          size="small"
          type="primary"
          @click="groupResetFun"
        >
          重置
        </el-button>
      </div>
      <div
        class="bottom_box"
        v-infinite-scroll="load"
        :infinite-scroll-disabled="disabled"
        infinite-scroll-distance="80px"
      >
        <UserGroupCard
          ref="UserGroupCardRef"
          :memberList="groupList"
          @reciverUserGroup="reciverUserGroup"
        />
        <p v-if="!noMore && groupList.length > 0" class="loading-text">
          加载中...
        </p>
        <p v-if="noMore && groupList.length > 0" class="loading-text">
          没有更多了
        </p>
        <div class="content-no" v-if="groupList.length == 0">
          暂无数据
        </div>
      </div>
    </div>
    <div
      class="center_box"
      v-infinite-scroll="messageLoad"
      :infinite-scroll-disabled="disabled2"
      infinite-scroll-distance="80px"
      v-loading="contentLoading"
      id="user-detail-box"
    >
      <SingleMessage
        v-for="item in messageList"
        :key="item.id"
        :singleInfo="item"
        :detailType="true"
        @updateFun="updateFun"
      />
      <p v-if="!noMore2 && messageList.length > 0" class="loading-text">
        加载中...
      </p>
      <p v-if="noMore2 && messageList.length > 0" class="loading-text">
        没有更多了
      </p>
      <div class="content-no" v-if="messageList.length == 0">
        暂无数据
      </div>
    </div>
    <div class="right_box">
      <searchRight @submitFun="submitFun" ref="searchRightRef" />
    </div>
  </div>
</template>

<script>
import { addTabs } from '@/assets/js/addTabs'
import { getSingleUserInfo, getMembers, messages1_Api } from '@/api/whatsApp'
import UserGroupCard from './components/UserGroupCard.vue'
import UserCard from './components/UserCard.vue'
import SingleMessage from '../search/componentens/SingleMessage.vue'
import { scrollTopFun } from '@/plugins/fun'
import { cacheMixin } from '@/mixins/mixin'
import { requestMixin } from '@/mixins/search'
export default {
  name: 'UserDetailPage',
  mixins: [cacheMixin, requestMixin],
  components: { UserGroupCard, UserCard, SingleMessage },
  data() {
    return {
      contentLoading: false,
      userInfo: null,
      groupList: [],
      messageList: [],
      total: 0,
      page_index: 1,
      loading: false,
      // 消息
      pageIndex: 1,
      loading2: false,
      total2: 0,
      groupId: null,
      listScrollTop: 0,
      disabled2: true,
      // 查询条件
      queryForm: {}
    }
  },

  async created() {
    this.getUserInfo(this.$route.params.userId).then(() => {
      this.getMembersList()
      this.$nextTick(() => {
        // this.disabled2 = true
        this.requestFun()
      })
    })
  },
  activated() {
    scrollTopFun('user-detail-box', 'set', this.listScrollTop)
  },
  deactivated() {
    this.listScrollTop = scrollTopFun('user-detail-box', 'get')
  },

  computed: {
    noMore() {
      return this.groupList.length >= this.total
    },
    disabled: {
      get() {
        return this.loading || this.noMore
      },
      set() {}
    },
    noMore2() {
      return this.messageList.length >= this.total2
    }
    // disabled2: {
    //   get() {
    //     return this.loading2 || this.noMore2
    //   },
    //   set() {}
    // }
  },
  methods: {
    getUserInfo(id) {
      return new Promise((resolve, reject) => {
        const that = this
        getSingleUserInfo(id)
          .then(res => {
            this.userInfo = Object.assign({}, res)
            resolve()
            addTabs(that, res.name, res.id, '/user/detail/')
          })
          .catch(() => {
            this.$message.warning('无法获取该用户详情！')
            setTimeout(() => {
              this.$router.go(-1)
            }, 1500)
          })
      })
    },
    // 获得群
    async getMembersList() {
      const data = {
        user: this.$route.params.userId,
        page_index: this.page_index
      }
      this.loading = true
      const res = await getMembers(data)
      this.loading = false
      if (res.items.length < res.page_size) {
        this.disabled = true
      }
      this.groupList = [...this.groupList, ...res.items]

      this.total = res.count
    },
    load() {
      this.page_index += 1
      this.getMembersList()
    },
    // 获得消息
    messageLoad() {
      this.requestFun()
    },
    async getMessageListInfo() {
      const data = {
        author: this.$route.params.userId,
        page_index: this.pageIndex,
        page_size: 20,
        group: this.groupId
      }
      this.contentLoading = true
      const res = await messages1_Api(data)
      if (res.items.length < res.page_size) {
        this.disabled2 = true
      }
      this.messageList = [...this.messageList, ...res.items]

      this.total2 = res.count
      this.contentLoading = false
    },
    requestFun() {
      const data = {
        author: this.$route.params.userId,
        page_index: this.pageIndex,
        page_size: 20,
        group: this.groupId
      }
      let rightObj = this.$refs.searchRightRef.queryForm

      let query = {
        ...data,
        ...rightObj
      }
      let search_obj = {}
      for (let i in query) {
        if (query[i]) {
          search_obj[i] = query[i]
        }
      }
      this.queryForm = { ...search_obj }
      if (this.queryForm.radio == 1) {
        this.getMsg1_msg_Scoll()
      } else if (this.queryForm.radio == 2) {
        this.getMsg2_msg_Scoll()
      } else if (this.queryForm.radio == 3) {
        this.getMsg3_msg_Scoll()
      }
    },
    // 群检索
    reciverUserGroup(val) {
      this.pageIndex = 1
      this.messageList = []
      this.groupId = val
      this.submitFun()
    },
    // 群重置
    groupResetFun() {
      this.pageIndex = 1
      this.messageList = []
      this.groupId = ''
      this.$refs.UserGroupCardRef.selectId = ''
      this.requestFun()
    },
    updateFun() {
      this.pageIndex = 1
      this.messageList = []
      this.groupResetFun()
    },
    //
    clearCache() {
      if (
        this.$vnode &&
        this.$vnode.parent &&
        this.$vnode.parent.componentInstance &&
        this.$vnode.parent.componentInstance.cache
      ) {
        const key =
          this.$vnode.key == null
            ? this.$vnode.componentOptions.Ctor.cid +
              (this.$vnode.componentOptions.tag
                ? `::${this.$vnode.componentOptions.tag}`
                : '')
            : this.$vnode.key
        const cache = this.$vnode.parent.componentInstance.cache
        const keys = this.$vnode.parent.componentInstance.keys
        if (cache[key]) {
          if (keys.length) {
            const index = keys.indexOf(key)
            if (index > -1) {
              keys.splice(index, 1)
            }
          }
          delete cache[key]
        }
      }
    },
    // 检索
    submitFun() {
      // this.disabled2 = true
      this.pageIndex = 1
      this.messageList = []
      this.requestFun()
    },
    // 刷新标签
    labelFormUpdateFun() {
      this.getUserInfo(this.$route.params.userId)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 70px);
  .left_box {
    width: 300px;
    height: 100%;
    padding: 10px;
    border: 1px solid #e5e5e5;
    box-shadow: 0 1px 0px 0 #d8d9d9;
    border-radius: 5px;

    .club_box {
      height: 38px;
      background: #f2f4f8;
      line-height: 38px;
      padding-left: 10px;
      display: flex;
      align-items: center;
      .btn {
        height: 30px;
        margin-left: 20px;
      }
      span {
        color: #ff5400;
      }
    }
    .bottom_box {
      height: calc(60% - 40px);
      // background: pink;
      padding: 10px 0;
      overflow-y: auto;
      position: relative;
    }
  }

  .center_box {
    width: calc(100% - 620px);
    height: 100%;

    border: 1px solid #e5e5e5;
    box-shadow: 0 1px 0px 0 #d8d9d9;
    border-radius: 5px;
    padding: 10px;
    overflow: auto;
    position: relative;
    .nomore_box {
      text-align: center;
      font-size: 14px;
      color: #949698;
    }
  }
  .right_box {
    width: 300px;
    height: 100%;
  }
  .loading-text {
    font-size: 12px;
    color: #949698;
    text-align: center;
  }
  .content-no {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #949698;
  }
}
</style>
